<template>
	<div class="root">
		<div class="selectNav">
			<van-dropdown-menu>
				<van-dropdown-item
					@change="handleSelect"
					v-model="selectForm.openSpace"
					:options="openSpace"
				/>
			</van-dropdown-menu>
			<van-dropdown-menu>
				<van-dropdown-item
					@change="handleSelect"
					v-model="selectForm.forCrowd"
					:options="forCrowd"
				/>
				<van-dropdown-item
					@change="handleSelect"
					v-model="selectForm.status"
					:options="status"
				/>
				<van-dropdown-item
					@change="handleSelect"
					v-model="selectForm.heat"
					:options="heat"
				/>
			</van-dropdown-menu>
			<van-search
				v-model="selectForm.input"
				placeholder="请输入搜索关键词"
				clearable
				@search="handleSelect"
			/>
		</div>
		<div class="main">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="getFilCourseList"
				offset="10"
			>
				<div
					@click="handleCourseInfo(item)"
					v-for="(item, i) in courseList"
					:key="i"
					class="course-list"
				>
					<van-image
						width="100%"
						fit="cover"
						:src="item.courseInfo.coursePicture"
					/>
					<p>{{ item.courseInfo.courseName }}</p>
					<p>{{ item.courseSite.siteName }}</p>
					<p>{{ item.courseInfo.courseLimitGrade }}年级</p>
				</div>
			</van-list>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import {
	getGeneration_api,
	getOpenSite_api,
	getCourseStatus_api,
	getFilCourseList_api,
} from '@/api/course.js'
export default {
	data() {
		return {
			loading: false,
			finished: false,
			selectForm: {
				forCrowd: undefined,
				openSpace: undefined,
				status: undefined,
				heat: 'create_time',
				input: undefined, // 搜索值
			},
			forCrowd: [], // 面向人群数据
			openSpace: [], // 开设场地
			heat: [
				{ value: 'create_time', text: '最新' },
				{ value: 'course_heat', text: '最热' },
			],
			status: [], // 预约状态
			courseList: [], // 课程数据
			currentPage: 1, // 当前页码
			pageSize: 5, // 每页条数
			total: 0, // 总条数
		}
	},
	methods: {
		// 点击课程
		handleCourseInfo(item) {
			this.$router.push(
				'/mobile/courseInfo?courseId=' + item.courseInfo.id
			)
		},
		upData(list) {
			// 数据处理
			if (!list) return
			list.forEach((item) => {
				if (item.courseLimitGrade) item.courseLimitGrade += '年级'
				if (item.courseLabel && typeof item.courseLabel === 'string')
					item.courseLabel = item.courseLabel.split(',')
				// 开始时间
				let sTime = moment(new Date(item.courseStartTime)).format(
					'HH:mm'
				)
				// 结束时间
				let eTime = moment(new Date(item.courseEndTime)).format('HH:mm')
				let arr = ['一', '二', '三', '四', '五', '六', '日']
				item.timer = sTime + '-' + eTime
				item.time =
					moment(new Date(item.courseStartWeek)).format(
						'YYYY-MM-DD'
					) +
					' 至 ' +
					moment(new Date(item.courseEndWeek)).format('YYYY-MM-DD') +
					' 每周' +
					(!isNaN(Number(item.courseDay))
						? arr[item.courseDay - 1]
						: item.courseDay) +
					' ' +
					item.timer
			})
		},
		// 获取年级筛选条件
		async getGeneration() {
			let res = await getGeneration_api({ code: 'target_generation' })
			this.forCrowd = res.data.map((item) => {
				return {
					text: item.dictValue,
					value: item.dictKey,
				}
			})
			this.forCrowd.unshift({
				text: '所有年级',
				value: undefined,
			})
		},
		// 获取开设场地筛选条件
		async getOpenSite() {
			let res = await getOpenSite_api({ tenantId: this.$tenantId })
			this.openSpace = res.data.map((item) => {
				return {
					text: item.deptName,
					value: item.id,
				}
			})
			this.openSpace.unshift({
				text: '所有地点',
				value: undefined,
			})
		},
		// 获取预约状态筛选条件
		async getCourseStatus() {
			let res = await getCourseStatus_api({ code: 'course_status' })
			// 过滤出可预约和不可预约
			res.data = res.data.filter(
				(item) =>
					item.dictValue.includes('预约') ||
					item.dictValue.includes('候补')
			)
			this.status = res.data.map((item) => {
				return {
					text: item.dictValue,
					value: item.dictKey,
				}
			})
			this.status.unshift({
				text: '所有状态',
				value: undefined,
			})
		},
		// 获取筛选后课程列表
		async getFilCourseList() {
			this.loading = true
			// 发送请求
			let { code, data } = await getFilCourseList_api({
				// courseDetail,
				// query,
				courseLimitGrade: this.selectForm.forCrowd,
				courseStatus:
					this.selectForm.status, //预约状态
				createDept: this.selectForm.openSpace, //开设场地
				courseName: this.selectForm.input,
				current: this.currentPage,
				size: this.pageSize,
				descs: this.selectForm.heat,
			})
			this.upData(data.records)
			if (data.records.length > 0) {
				this.currentPage++
				this.courseList = this.courseList.concat(data.records)
				this.total = data.total
			} else {
				this.finished = true
			}
			this.loading = false
		},
		// 改变筛选条件
		handleSelect() {
			// 改变条件后，先修改当前页码为1
			this.currentPage = 1
			this.courseList = []
			// 发送请求
			this.getFilCourseList()
		},
	},
	created() {
		this.getGeneration()
		this.getOpenSite()
		this.getCourseStatus()
		// this.getFilCourseList();
	},
}
</script>

<style lang="less" scoped>
.root {
	height: 100%;
	.main {
		height: calc(100% - 104px);
		overflow-y: auto;
		/*隐藏滚动条，当IE下溢出，仍然可以滚动*/
		-ms-overflow-style: none;
		/*火狐下隐藏滚动条*/
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		/*解决ios上滑动不流畅*/
		-webkit-overflow-scrolling: touch;
		.course-list {
			border: 0.1px solid #d9ecff;
			// width: calc(100% - 0.2px);
			height: 60vw;
			border-radius: 10px;
			margin: 3%;
			padding: 2%;
			background-color: #fff;
			.van-image {
				height: calc(100% - 60px);
			}
			p {
				font-size: 0.8em;
				line-height: 20px;
				letter-spacing: 2px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
/deep/.van-dropdown-menu__bar {
	height: 35px;
}
/deep/.van-cell__title {
	flex: 10;
}
/deep/.van-search {
	padding: 0 12px;
	.van-search__content {
		background-color: #fff;
	}
}
</style>
